@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

h1, .h1 {
  font-size: 32px;
  font-weight: 500;
}
h2, .h2 {
  font-size: 24px;
  font-weight: 500;
}
h3, .h3 {
  font-size: 20px;
}
h4, .h4 {
  font-size: 18px;
}