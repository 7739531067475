@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?iut389');
  src:  url('fonts/icomoon.eot?iut389#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?iut389') format('truetype'),
    url('fonts/icomoon.woff?iut389') format('woff'),
    url('fonts/icomoon.svg?iut389#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download:before {
  content: "\e951";
}
.icon-transactions:before {
  content: "\e950";
}
.icon-like:before {
  content: "\e943";
}
.icon-table-column-sort:before {
  content: "\e948";
}
.icon-billboards-fill:before {
  content: "\e949";
}
.icon-billboards:before {
  content: "\e94a";
}
.icon-eye:before {
  content: "\e94b";
}
.icon-eye-slash:before {
  content: "\e945";
}
.icon-arrow_left2:before {
  content: "\e94c";
}
.icon-arrow_up2:before {
  content: "\e94d";
}
.icon-arrow_down2:before {
  content: "\e94e";
}
.icon-arrow-right2:before {
  content: "\e946";
}
.icon-attention-circle:before {
  content: "\e947";
}
.icon-verified:before {
  content: "\e942";
}
.icon-camera:before {
  content: "\e944";
}
.icon-money:before {
  content: "\e938";
}
.icon-picture-frame:before {
  content: "\e939";
}
.icon-slots-2:before {
  content: "\e93a";
}
.icon-more:before {
  content: "\e93b";
}
.icon-details:before {
  content: "\e93c";
}
.icon-edit:before {
  content: "\e93d";
}
.icon-folder:before {
  content: "\e93e";
}
.icon-archive:before {
  content: "\e93f";
}
.icon-key:before {
  content: "\e940";
}
.icon-delete:before {
  content: "\e941";
}
.icon-lock:before {
  content: "\e937";
}
.icon-campaign:before {
  content: "\e92b";
}
.icon-dashboard:before {
  content: "\e92c";
}
.icon-document:before {
  content: "\e92d";
}
.icon-listing:before {
  content: "\e92e";
}
.icon-logout:before {
  content: "\e92f";
}
.icon-payments:before {
  content: "\e930";
}
.icon-profile-circle:before {
  content: "\e931";
}
.icon-reward:before {
  content: "\e932";
}
.icon-security:before {
  content: "\e933";
}
.icon-settings:before {
  content: "\e934";
}
.icon-support:before {
  content: "\e935";
}
.icon-transaction:before {
  content: "\e936";
}
.icon-illuminated2:before {
  content: "\e928";
}
.icon-static:before {
  content: "\e927";
}
.icon-bulb:before {
  content: "\e921";
}
.icon-checkmark_simple:before {
  content: "\e922";
}
.icon-dynamic:before {
  content: "\e923";
}
.icon-calendar2:before {
  content: "\e924";
}
.icon-typecharacteristic:before {
  content: "\e925";
}
.icon-information_info:before {
  content: "\e926";
}
.icon-slots:before {
  content: "\e91f";
}
.icon-calendar:before {
  content: "\e920";
}
.icon-cost:before {
  content: "\e91e";
}
.icon-profile-2user:before {
  content: "\e91b";
}
.icon-hashtag:before {
  content: "\e91c";
}
.icon-scan-barcode:before {
  content: "\e91d";
}
.icon-minus:before {
  content: "\e919";
}
.icon-plus-reg:before {
  content: "\e91a";
}
.icon-arrow_up_long:before {
  content: "\e94f";
}
.icon-arrow-back:before {
  content: "\e917";
}
.icon-arrow-next:before {
  content: "\e918";
}
.icon-filter:before {
  content: "\e906";
}
.icon-user:before {
  content: "\e916";
}
.icon-heart-active:before {
  content: "\e907";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-top:before {
  content: "\e914";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e915";
}
.icon-circle:before {
  content: "\e902";
}
.icon-clock:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e904";
}
.icon-compas:before {
  content: "\e905";
}
.icon-heart:before {
  content: "\e908";
}
.icon-light:before {
  content: "\e909";
}
.icon-loudspeaker:before {
  content: "\e90a";
}
.icon-pin:before {
  content: "\e90b";
}
.icon-road:before {
  content: "\e90c";
}
.icon-round-check:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e90e";
}
.icon-share:before {
  content: "\e90f";
}
.icon-size:before {
  content: "\e910";
}
.icon-stadium:before {
  content: "\e911";
}
.icon-plus:before {
  content: "\e912";
}
.icon-upload:before {
  content: "\e913";
}
.icon-draw:before {
  content: "\e929";
}
.icon-chevron-down:before {
  content: "\e92a";
}
