.button {
  border: 2px solid transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 100px;
  transition: all 400ms;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 8px;

  &.fullWidth {
    width: 100%;
  }
  &.small {
    &:not(.noPadding) {
      padding: 8px 16px;
    }
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border-radius: 100px;
  }

  &:not(.noPadding) {
    padding: 13px 24px; // 50px height
  }

  img {
    max-height: 20px !important;
    width: auto !important;
  }

  &.disabled {
    pointer-events: none;
  }

  &.primary {
    background: var(--charcoalColor);
    color: var(--whiteColor);
    border-color: var(--charcoalColor);

    &:hover {
      background-color: var(--lightCharcoalColor);
      border-color: var(--lightCharcoalColor);
    }

    &.disabled {
      background-color: var(--gray150);
      border-color: var(--gray150);
      color: var(--gray400);
    }
  }
  &.blue {
    background: var(--primary-blue);
    color: var(--whiteColor);
    border-color: var(--primary-blue);

    &:hover {
      background-color:rgba($color: #0191F6, $alpha: 0.7);
      border-color: rgba($color: #0191F6, $alpha: 0.7);
    }

    &.disabled {
      background-color: var(--gray150);
      border-color: var(--gray150);
      color: var(--gray400);
    }
  }

  &.secondary {
    background-color: transparent;
    border-color: var(--charcoalColor);
    color: var(--charcoalColor);

    &:hover {
      border-color: var(--lightCharcoalColor);
      background-color: rgba(0, 0, 0, 0.04);
      color: var(--lightCharcoalColor);
    }

    &.disabled {
      border-color: var(--mediumGreyColor);
      color: var(--mediumGreyColor);
      background-color: transparent;
    }
  }

  &.secondary-light {
    background-color: transparent;
    border-color: var(--lightGrayColor);
    color: var(--charcoalColor);

    &:hover {
      border-color: var(--charcoalColor);
      background-color: transparent;
      color: var(--charcoalColor);
    }

    &.disabled {
      border-color: var(--mediumGreyColor);
      color: var(--mediumGreyColor);
      background-color: transparent;
    }
  }

  &.default {
    color: var(--charcoalColor);
  }

  &.default-accent{
    color: var(--primary-blue);
  }

  &.default,
  &.default-accent {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
    text-decoration: underline;
    font-weight: 500;
    &.requestInfoButton {
      padding: 13px 0;
    }
    &:not(.noPadding) {
      padding: 13px;
    }

    &:hover {
      color: var(--lightCharcoalColor);
    }

    &.disabled {
      color: var(--mediumGreyColor);
    }
  }

  &.border {
    border-radius: 2px;
    border: 1px solid var(--borderColor);
    background: #FFF;

    &:hover {
      border-color: var(--charcoalColor);
    }
  }

  .imageIcon {
    display: block;

    span {
      display: block !important;
    }

    img {
      display: block;
    }
  }
}