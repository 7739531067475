.header {
  padding: 64px 0 16px 0;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  img {
    height: 36px;
    width: auto;
  }
}