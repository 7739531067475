.homeWrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
}
.infoCol {
  padding: 110px 15px;
  max-width: 590px;
  @media (max-width: 1024px) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    padding-top: 160px;
    padding-bottom: 40px;
  }
  h1 {
    font-size: 60px;
    font-weight: 700;
    line-height: 64px;
    margin-bottom: 16px;
    @media (max-width: 767px) {
      font-size: 40px;
      line-height: 44px;
    }
    @media (max-width: 575px) {
      br {
        display: none;
      }
    }
    span {
      font-weight: 400;
      font-style: italic;
    }
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
  .btnGroup {
    display: flex;
    gap: 12px;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 16px;
      margin-top: 40px;
    }
    button {
      flex: 1 1 auto;
      white-space: nowrap;
      margin-top: 40px;
      @media (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
}
.imageCol {
  padding: 0 15px;
  flex: calc(100% - 590px);
  max-width: calc(100% - 590px);
  @media (max-width: 1024px) {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
  .heroAnimWrapper {
    position: relative;
    @media (max-width: 1024px) {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .imageColAnimation {
      width: 130%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 1024px) {
        width: 100%;
        transform: translateY(0);
        position: static;
      }
    }
  }
  .heroAnimWrapper,
  img {
    width: auto;
    max-width: 832px;
    height: auto;
    min-height: 100vh;
    @media (max-width: 1024px) {
      min-height: unset;
      max-width: 100%;
    }
  }
}