:root {
  --inputBorderColor: #e0e1e3;
  --charcoalColor: #0e0d23;
  --mediumGreyColor: #858490;
  --darkGreyColor: #565562;
  --textPrimaryColor: #2d333a;
  --whiteColor: #fff;
  --lightCharcoalColor: #3b3b40;
  --greyColor: #565562;
  --lightGrayColor: #e8e8e8;
  --blackColor: #000;
  --green5Color: #dff0ed;
  --greenColor: #2a9928;
  --lightGreenColor: #d9e9d9;
  --green6Color: #EFF8F6;
  --blue3Color: #c9ddff;
  --blue2Color: #bbd5ff;
  --peachColor: #fef3e9;
  --peach2Color: #fde1c8;
  --yellowColor: #f4f5ad;
  --pinkColor: #F4D9ED;
  --errorColor: #ff8181;
  --perpleColor: #e1d3ff;
  --borderColor: #c2c8d0;
  --borderColor2: #85849033;
  --borderColor3: #EBEBEB;
  --dividerColor: #e7e7e9;
  --backgroundColor: rgba(14, 13, 35, 0.4);
  --mainFont: 'Rubik', sans-serif;
  --blueColor: #2E5EF6;
  --linkColor: #1765CE;
  --grey50: #F4F4F4;
  --editColor: #666;
  --grey10: #F7F7FB;

  // NEW DESIGN COLORS
  --primary-dark-blue:#111022;
  --primary-blue: #0191F6;
  --light-blue: #EDF4FD;
  --light-blue05: #EDF4FD80;
  --gray50: #F4F5FA;
  --gray100: #EEEEF6;
  --gray150: #D8D8E8;
  --gray300: #A9A9BF;
  --gray200: #C6C6D5;
  --gray300: #B0B0C0;
  --gray400: #8D8DA2;
  --gray500: #797991;
  --gray600: #64647A;
  --gray700: #4D4D62;
  --gray850: #22222F;
  --gray800: #39394B;
  --green200: #B9F1D3;
  --green400: #33D17C;
  --yellow150: #FBEDC7;
  --yellow200:#FCE8AE;
  --statuses: #FFD8BA;
  --info: #11102280;
  --warning: #FE7D1A;
  --blue100: #D8E9FD;
  --blue150: #B3D5FD;
  --blue200: #8DC0FC;
  --primaryPink: #FD206A;
  --pink50: #FBF2F5;
  --error500: #FD412F;
  --success-green: #09C754;
  --turquoise50: #EFFFFC;
  --primary-turquoise: #09C99E;
  --turquoise100: #DEFFF9;
  --error100: #FFD9D5;
  --success100: #C9F4DA;


  // SIZES
  --glob-container-max-width: 640px;
  --glob-container-padding: 40px 20px;
  --client-sidebar-width: 250px;
}