.tr-fade-enter>div {
  opacity: 0;
}

.tr-fade-enter-active>div {
  opacity: 1;
}

.tr-fade-exit>div {
  opacity: 1;

}

.tr-fade-exit-active>div {
  opacity: 0;
}

.tr-fade-enter-active>div,
.tr-fade-exit-active>div {
  transition: opacity 300ms;
}

///
.tr-fade-simple-enter {
  opacity: 0;
}

.tr-fade-simple-enter-active {
  opacity: 1;
}

.tr-fade-simple-exit {
  opacity: 1;

}

.tr-fade-simple-exit-active {
  opacity: 0;
}

.tr-fade-simple-enter-active,
.tr-fade-simple-exit-active {
  transition: opacity 150ms;
}
///

.fade-scale-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-scale-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-scale-exit {
  opacity: 1;
}
.fade-scale-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.tr-slide-enter  {
  opacity: 0;
  transform: translateX(15%);
}
.tr-slide-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.tr-slide-exit {
  opacity: 1;
  transform: translateX(0%);
}
.tr-slide-exit-active {
  opacity: 0;
  transform: translateX(15%);
}

.tr-slide-enter-active,
.tr-slide-exit-active {
  transition: opacity 150ms, transform 150ms;
}
