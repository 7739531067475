.inputWrapper {
  --input-height:60px;
  position: relative;

  &:not(.noPadding) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &.sm {
    --input-height:48px;
  }

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: block;
    margin-bottom: 8px;
    color: var(--gray500);

    &.large {
      font-weight: 500;
      margin-bottom: 20px;
      font-size: 18px;
      color: var(--primary-dark-blue);
    }

    &.labelInner {
      position: absolute;
      left: 20px;
      top: 0;
      height: var(--input-height);
      display: flex;
      align-items: center;
      pointer-events: none;
    }
  }

  &.errorWrapp {
    input {
      border-color: var(--errorColor);
      box-shadow: 0px 0px 5px 0px rgba(234, 68, 68, 0.51);
    }
  }

  &.col12 {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }

  &.col6 {
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;

    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;

      // this need to be moved out of component styles, because such kind modifications used locally bun not global
      // &+.col6 {
      //   margin-top: 20px;
      // }
    }
  }

  &.col4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    width: 33.3333%;
  }

  &.col3 {
    flex: 0 0 25%;
    max-width: 25%;
    width: 25%;
  }

  &.col2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    width: 16.6667%;
  }
}

.inputInner {
  width: 100%;
  position: relative;
}

.error {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 6px;
  font-size: 12px;
  color: var(--errorColor);
}

.textarea,
.input {
  padding: 0 calc(20px + var(--inner-label-width, 0px));
  height: var(--input-height);
  display: block;
  width: 100%;
  border: 1px solid var(--inputBorderColor);
  border-radius: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: var(--charcoalColor);
  transition: all 400ms;

  &::placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: var(--mediumGreyColor);
    transition: all 400ms;
  }

  &:focus {
    outline: 1px solid var(--blueColor);

    &::placeholder {
      text-indent: 20px;
      opacity: 0;
    }
  }

  &.whiteBg {
    background: #fff;
  }

  &:disabled {
    background: var(--lightGrayColor);
  }

  @media (max-width: 480px) {
    // ios accessibility requirements
    font-size: 16px;
  }
}

.textarea {
  height: 104px;
  resize: none;
  padding: 20px;
  vertical-align: top;

  @media (max-width: 640px) {
    //height: 100%;
  }
}
.icons {
  position: absolute;
  right: 0;
  top: 0;
  height: var(--input-height);
  display: flex;
  align-items: center;
  gap: 6px;
  padding-right: 20px;

  .icon {
    font-size: 24px;
  }
}

.errorIcon {
  color: var(--error500);
}
.eyeIcon {
  cursor: pointer;
}