.content {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
}
html,
body {
  font-family: var(--mainFont);
  font-weight: 400;
  color: var(--primary-dark-blue);

  &::-webkit-scrollbar {
    width: 0;
  }

  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  background-color: var(--whiteColor);
}

.modal-open {
  position: fixed;
  overflow: hidden;
}

#root {
  min-height: 100vh;
  min-height: 100dvh; // for modern browsers and mobile browsers
  display: flex;
  flex-direction: column;
}

.scrollbar-custom {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--lightGrayColor);
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--mediumGreyColor);
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--charcoalColor);
  }
}

.gm-svpc div img {
  position: relative !important;
}
.simple-text-link {
  color: var(--blueColor, #2E5EF6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.grecaptcha-badge {
  right: -270px !important;
  opacity: 0 !important;
}

.fw-regular {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-black {
  font-weight: 900;
}
.react-international-phone-input-container.phone-input-form {
  width: 100%;
  display: flex;
  .react-international-phone-input {
    width: calc(100% - 75px);
    border: 1px #D8D8E8 solid;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--charcoalColor);
    padding: 19px 16px;
    border-radius: 0 12px 12px 0;
    height: auto;
  }
  .react-international-phone-country-selector-dropdown {
    top: 65px;
  }
  .react-international-phone-country-selector {
    width: 76px;
    margin-right: -1px;
    border-right: none;
    .react-international-phone-country-selector-button {
      width: 100%;
      height: auto;
      padding: 16px 16px 16px 13px;
      border: 1px #D8D8E8 solid;
      border-radius: 12px 0 0 12px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      img {
        height: 28px;
        width: auto;
        margin: 0;
        display: block;
      }
      .react-international-phone-country-selector-button__dropdown-arrow {
        position: absolute;
        right: 8px;
        width: 16px;
        height: 16px;
        top: 50%;
        transform: translateY(-50%);
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border: none;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
          content: '\e900';
          font-size: 10px;
          transition: all 400ms;
        }
      }
      &--active {
        .react-international-phone-country-selector-button__dropdown-arrow {
          &::before {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.custom-multiselect-wrapper {
  .custom-multiselect__indicators {
    display: none;
  }
  .custom-multiselect__value-container {
    padding: 10px 16px;
  }
  .custom-multiselect__input {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    color: var(--charcoalColor) !important;
  }
  .custom-multiselect__control {
    border-radius: 12px;
    border: 1px solid var(--gray300, #B0B0C0);
    background: var(--whiteColor, #FFF);
    min-height: 60px;
    gap: 8px;
    &--is-focused {
      border: 1px solid var(--gray300, #B0B0C0);
      outline: 1px solid var(--blueColor);
      box-shadow: none;
    }
    .custom-multiselect__multi-value {
      padding: 6px 32px 6px 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--primary-dark-blue);
      border-radius: 8px;
      background: var(--gray100);
      margin: 4px;
      position: relative;
      .custom-multiselect__multi-value__label {
        padding: 0;
      }
      .custom-multiselect__multi-value__remove {
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 400ms;
        font-size: 10px;
        &:hover {
          color: var(--errorColor);
          background: transparent;
        }
        &::before {
          content: '\e904';
        }
        svg {
          display: none;
        }
      }
    }
  }
  .custom-multiselect__placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color:var(--gray500);
  }
  &--warning {
    .custom-multiselect__control {
      border-color: #FD412F;
    }
    .custom-multiselect__value-container {
      padding-right: 50px;
      background: url(../images/icons/warning.svg) no-repeat calc(100% - 20px) center;
      background-size: 24px 24px;
    }
  }
  &--disable {
    .custom-multiselect__control {
      overflow: hidden;
      background: #F4F5FA;
    }
    .custom-multiselect__input-container {
      display: none;
    }
    .custom-multiselect__value-container {
      background: #F4F5FA;
    }
  }
}

.skeleton-placeholder {
  transition: all 0.2s;
  background-color: var(--gray50);
  background-image: linear-gradient(to right, var(--gray50) 10%, var(--gray100) 18%, var(--gray50) 33%);
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-size: 800px 104px !important;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

// TOOLTIPS
.react-tooltip.global-tooltip {
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  background: var(--whiteColor);
  border-radius: 16px 16px 16px 0;
  padding: 22px 16px;
  --rt-opacity: 1;
  max-width: 240px;
  font-weight: 400;

  &.theme-light {
    background: var(--whiteColor);
  }

  &.theme-blue {
    padding: 16px;
    background: var(--primary-blue);
    border-radius: 8px;
    max-width: 254px;
  }
}
.advertising-tooltip {
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  background: var(--whiteColor);
  border-radius: 16px 16px 16px 0;
  padding: 22px 16px;
  --rt-opacity: 1;
  max-width: 240px;
  width: 100%;
}
.react-transform-component {
  div {
    width: 100% !important;
  }
}
.react-transform-component,
.react-transform-wrapper {
  width: 100% !important;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: right;
}