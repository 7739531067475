.checkboxWrapper {

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
            user-select: none;
  }
  span {
    padding-left: 34px;
    position: relative;
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--charcoalColor);
    a {
      font-weight: 500;
      text-decoration-line: underline;
      transition: all 400ms;
      &:hover {
        color: var(--primary-blue);
      }
    }
    &::before {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 5px;
      border: 1px solid #E0E1E3;
      background-color: var(--whiteColor);
      background-repeat: no-repeat;
      background-position: center center;
      transition: all 400ms;
    }
  }
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 12px;
    border: 1px solid var(--charcoalColor);
    outline: none;
    cursor: pointer;
    position: absolute !important;
    opacity: 0;
    pointer-events: none;
    &.checked {
      background-color: var(--charcoalColor);
      position: relative;
      & + span {
        &::before {
          content: "";
          background-image: url('../../../../assets/images/icons/check-mark.svg');
          background-color: var(--charcoalColor);
        }
      }
      &::before {
        content: "";
        color: #fff;
        width: 100%;
        height: 100%;
        background-image: url('../../../../assets/images/icons/check-mark.svg');
        background-repeat: no-repeat;
        position: absolute;
        background-position: center center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:disabled {

      border-color: var(--darkGreyColor);
      background-color:var(--whiteColor);

      & + span {
        color: var(--darkGreyColor);
        &::before {
          border-color: var(--darkGreyColor);
        }
      }
    }

    &:focus {
      box-shadow: 0 0 0 1px var(--blueColor);
    }
  }
}