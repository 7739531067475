.signWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(14, 13, 35, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  @media (max-width: 640px) {
    background-color: var(--whiteColor);
  }
}
.modalTitle {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 40px;
  }
}
.modalSubtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-bottom: 24px;
}
.passwordRules {
  margin-top: 8px;
  padding-left: 10px;
  padding-right: 10px;

  ul {
    li {
      display: flex;
      //align-items: center !important;
      gap: 10px;
      .validIcon {
        width: 20px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        padding-top: 0 !important;
      }
      img {
        width: auto !important;
        height: 15px !important;
      }
    }
  }
}

.signForm {
  width: 100%;
}

.signInner {
  width: 100%;
  max-width: 600px;
  padding: 40px;
  // margin: 40px 0;
  flex-direction: column;
  background-color: var(--whiteColor);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 640px) {
    margin: 0;
    padding: 32px 16px 16px;

    h3 {
      font-size: 20px;
    }
  }

  @media (max-width: 360px) {
    padding-left: 0;
    padding-right: 0;
  }

  h3 {
    margin-bottom: 20px;
    text-align: center;
  }
}

.formRowButton {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 30px;

  button {
    width: 100%;
  }
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;

  &+.formRow {
    margin-top: 20px;
  }

  &.formRowDropdown {
    margin-left: 0;
    margin-right: 0;

    .dropdown {
      width: 100%;
    }
  }

  &.rowDirectionColumn {
    flex-wrap: nowrap;

    & > div {
      flex: 0 0 calc(50% - var(--formRowGap)/2);
      max-width: calc(50% - var(--formRowGap)/2);
      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;

      }
      //width: calc(50% - var(--formRowGap)/2);
    }
  }
}

.logo {
  width: 255px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }
}

.copyText {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: var(--mainFont);
  color: var(--mediumGreyColor);
  margin-top: 20px;

  a {
    color: var(--charcoalColor);
    text-decoration: underline;
  }
}

.signType {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;
  font-family: var(--mainFont);
  margin-top: 20px;
  padding: 0 8px 0 0;

  @media (max-width: 360px) {
    font-size: 14px;
  }
}

.changeType {
  padding-left: 8px;
  color: #2e5ef6;
  cursor: pointer;
  transition: all 400ms;

  &:hover {
    color: var(--mediumGreyColor);
  }

  @media (max-width: 360px) {
    padding-left: 4px;
  }
}

.alignedRight {
  margin-left: auto;
}

.connectWithSocial {
  margin-top: 32px;
  padding-top: 20px;
  position: relative;
  overflow-x: hidden;

  &::before {
    content: '';
    width: calc(100% + 80px);
    height: 1px;
    background-color: #b3cdf6;
    position: absolute;
    left: -40px;
    top: 0;
  }

  .title {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  button {
    margin-top: 20px;
    width: 100%;
  }
}