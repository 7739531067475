.modalSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  img {
    max-height: 147px;
    width: auto;
    margin-bottom: 18px;
  }
  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray700);
    margin-top: 8px;
  }

}