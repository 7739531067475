.phoneInput {
  width: 100%;

  &:disabled {
    background: var(--lightGrayColor);
  }
}

.formCol {

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: block;
    margin-bottom: 8px;
    color: var(--gray500);
  }

  padding-left: 10px;
  padding-right: 10px;

  &.formColNoPadding {
    padding-left: 0;
    padding-right: 0;
  }
}

.col6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.unset {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.error {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 6px;
  font-size: 12px;
  color: var(--errorColor);
}