.loaderWrapper {
  opacity: 0;
  visibility: hidden;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.4s ease;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 99;

  &.inner {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .ripple {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;

      div {
        position: absolute;
        border: 4px solid #656565;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

        &:nth-child(2) {
          animation-delay: -0.5s;
        }
      }
    }

    @keyframes lds-ripple {
      0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
      }
      4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
      }
      5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: 0;
        left: 0;
        width: 72px;
        height: 72px;
        opacity: 0;
      }
    }
  }

  &.deck {
    top: 20px;
    left: 248px;
    right: 20px;
    bottom: 20px;
  }
}
