.outer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 32;
}

.modal {
  padding: 50px 40px 35px 40px;
  position: relative;
  background-color: var(--whiteColor);
  box-shadow: 1px 4px 19px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  @media(max-width: 640px) {
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: none;
    &.full {
      min-height: 100vh;
      min-height: 100dvh;
      height: 100%;
    }

    &.mobile {
      border-radius: 8px 8px 0px 0px;
      background-color: var(--whiteColor);
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  &.noPaddings {
    padding: 0;
  }
  &.pdfModal {
    max-width: 1000px;
    .close {
      //z-index: 999;
    }
  }
}
.modalBackground {
  background-color: rgba(14, 13, 35, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 32;
  backdrop-filter: blur(5px);
}

.close {
  position: absolute;
  padding: 20px;
  right: 0;
  top: 0;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: all 400ms;
  @media (max-width: 767px) {
    padding: 16px;
  }
  &:hover {
    color: var(--mediumGreyColor);
  }
}

.modalWrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  padding: 40px 10px;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  width: calc(100% - 40px);
  max-width: 620px;
  z-index: 33;
  @media (max-width: 767px) {
    max-width: calc(100% - 40px);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.scrollDisabled{
    overflow-y: initial;
  }
  &.pdfModal {
    max-width: 1000px;
  }
  &.noPaddings {
    padding: 0;
  }

  @media (max-width: 640px) {
    width: 100%;
    max-width: calc(100% - 40px);
    padding: 0;

    &.full {
      height: 100%;
      background-color: var(--whiteColor);
    }

    &.mobile {
      height: 100%;
      background-color: rgba(14, 13, 35, 0.60);
      backdrop-filter: blur(4px);
    }
  }
}

.moduleContent {
  height: 100%;
}
