.footer {
  padding: 16px 0 64px 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  @media (max-width: 1024px) {
    position: static;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--charcoalColor);
  }
}